import './PrivacyPolicy.scss';
import {useTranslation} from 'react-i18next';

import GeneratePage from '../../generate-page/GeneratePage';

function PrivacyPolicy() {
  const [t] = useTranslation('pages/introduction/privacy_policy');
  const tree = {
    1: [1, 2, 3, 4, 5, 6],
    2: [{1: [1, 2, 3, 4, 5, 6, 7]}],
    3: [1, 2, 3, 4, 5],
    4: [1, 2, 3, 4, 5],
    5: [1, 2, 3],
    6: [1, 2],
    7: [1, 2, 3],
    8: [1],
    9: [1, 2],
    10: [1],
    11: [1],
  };

  const inserts = {
    link_underlot: (
      <a
        className='link_underlot'
        href='https://www.underlot.com/'
        target='_blank'
        rel='noreferrer'>
        www.underlot.com
      </a>
    ),
    'support@underlot': (
      <a
        className='support_underlot'
        href='support@underlot.com'
        target='_blank'>
        support@underlot.com
      </a>
    ),
    bold: '<span className="bold">',
    '/bold': '</span>',
  };

  return (
    <GeneratePage Name='PrivacyPolicy' tree={tree} inserts={inserts} t={t} />
  );
}
export default PrivacyPolicy;
