import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {io} from 'socket.io-client';

import {ShadowContainer} from '@containers';
import {Toast} from '@helpers';
import {useDispatchAsync} from '@hooks';
import {formatPrice} from '@utils';
import {getLotPrices} from '@actions/lot';
import {Logger} from '@helpers';

import {
  CLEAR_ACTIVE_LOT_DATA,
  UPDATE_ACTIVE_LOT_DATA,
} from '@actions/active-lot/types';
import {AUCTION_STATUS_LIVE, LOT_STATUS_ACTIVE} from '@helpers/models';

import {CLEAR_LOT_DATA, UPDATE_LOT_DATA} from '../../../store/lot/types';

import StartPrice from './components/start-price/StartPrice';
import ProgressBar from './components/progress-bar/ProgressBar';
import FinalPrice from './components/final-price/FinalPrice';
import ResultLot from './components/result-lot/ResultLot';
import MakeBit from './components/make-bit/MakeBit';
import CurrentBid from './components/current-bid/CurrentBid';
import ActiveLot from './components/active-lot/ActiveLot';
import ConfirmBit from './components/confirm-bit/ConfirmBit';
import PurchaseProgress from './components/purchase-progress/PurchaseProgress';

import './AuctionBloc.scss';

function AuctionBloc() {
  const [t] = useTranslation('pages/lot');
  const dispatch = useDispatchAsync();

  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const bidAllowed = useSelector(state => state.user.bidAllowed);
  const token = useSelector(state => state.user.token);
  const userId = useSelector(state => state.user.id);

  const [newBit, setNewBit] = useState(null);

  const lot = useSelector(state => state.lot);
  const activeLot = useSelector(state => state.activeLot);
  const lotId = lot.lotId;

  const activeLotRef = useRef(lot);
  useEffect(() => {
    activeLotRef.current = activeLot;
  }, [activeLot]);

  const lotRef = useRef(lot);
  useEffect(() => {
    lotRef.current = lot;
  }, [lot]);

  function set(field, value) {
    dispatch({type: UPDATE_LOT_DATA, payload: {field: field, value: value}});
  }
  function setActiveLotInfo(field, value) {
    dispatch({
      type: UPDATE_ACTIVE_LOT_DATA,
      payload: {field: field, value: value},
    });
  }
  const ENDPOINT = ['production', 'staging'].includes(process.env.NODE_ENV)
    ? window.location.protocol + '//' + window.location.host
    : 'http://localhost:5000';

  const socketRef = useRef(
    io(`${ENDPOINT}/bid`, {
      withCredentials: true,
      query: {token: token},
      autoConnect: false,
      transports: ['websocket'],
    })
  );
  const ws = socketRef.current;

  const WS_Events = {
    connect() {
      ws.emit('lot-watch', {lid: lotId});
      ws.emit('get-bids', {lid: lotId});
    },
    connect_error(data) {
      let msg = data?.message;
      switch (msg) {
        case 'Not authorized':
          msg = t('need_have_account');
          break;
        case 'Invalid token':
          msg = t('invalid_token');
          break;
        case 'Not allowed':
          msg = t('not_allowed');
          break;
        case 'xhr poll error':
          msg = t('xhr_poll_error');
          break;
        case 'websocket error':
          msg = t('websocket_error');
          break;
        case 'timeout':
          return;
        default:
          Logger.error('Unknown message for socket: ' + msg);
      }
      Toast.error(msg, 5000);
    },
    ready() {},
    bids(data) {
      if (lotId === data.lotId && data.bids && data.bids.length > 0) {
        set('bids', data.bids);
        if (!lotRef.current.lotProcessing) {
          dispatch(getLotPrices(lotRef.current.lotId));
        }
      }
    },
    'bid-waiting'(data) {
      Toast.info(
        `${t('bid_accepted')}, ${t('price')}: ${formatPrice(data['bidPrice'])}`
      );
    },
    'bid-approved'(data) {
      dispatch(getLotPrices(lotRef.current.lotId));
      const newPrice = formatPrice(data['bidPrice']);

      if (lotId === data.lotId) {
        const bids = lotRef.current.bids || [];
        bids.unshift(data);
        if (bids.length > 5) {
          bids.pop();
        }
        set('bids', bids);
        if (userId === data['userId']) {
          Toast.success(`${t('bid_approved')}. ${t('price')}: ${newPrice}`);
          if (!lotRef.current.isParticipant) {
            set('isParticipant', true);
            lotRef.current.isParticipant = true;
          }
        } else {
          Toast.info(`${t('new_bit')}! ${t('price')}: ${newPrice}`, 5000);
        }
      } else {
        Toast.info(
          `${t('new_bit_for_car')} ${data['lotName']}! ${t(
            'price'
          )}: ${newPrice}`
        );
      }
    },
    'bid-canceled'(data) {
      // auto bit не прийняв ставку
      if (userId === data.userId) {
        Toast.warning(
          `${t('your_bit')} ${formatPrice(data['bidPrice'])}
         ${t('need_reload_page')}`
        );
      }
      ws.emit('get-bids', {lid: lotId});
    },
    'bid-warning'(data) {
      Toast.warning(data);
    },
    'bid-error'(data) {
      let msg = '';
      switch (data) {
        case 'Bid error':
          msg = t('bid_error');
          break;
        case 'Bids not allowed':
          msg = t('bids_not_allowed');
          break;
        case 'User not found':
          msg = t('user_not_found');
          break;
        case 'Invalid lot':
          msg = t('invalid_lot');
          break;
        case 'Invalid auction':
          msg = t('invalid_auction');
          break;
        case 'Auction finished':
          msg = t('auction_finished');
          break;
        case 'Invalid price':
          msg = t('invalid_price');
          break;
        case 'Invalid bid step':
          msg = t('invalid_bid_step', {
            list: lot.bidSteps.join(', '),
            step: lot.bidSteps[0],
          });
          break;
        case 'User price limit':
          msg = t('user_price_limit');
          break;
        case 'User lots limit':
          msg = t('user_lots_limit');
          break;
        case 'Waiting bid':
          msg = t('waiting_bid');
          break;
        case 'timeout':
          return;
        default:
          Logger.error('Unknown bid-error for socket: ' + data);
      }
      Toast.error(msg);
    },
    'lot-status'(data) {
      if (lotId === data.lotId) {
        set('lotStatus', data.status);
      }
    },
    'active-lot'(data) {
      set('currentLotIndex', data.lotIndex);
      set('currentLotId', data.lotId);
    },
    'active-lot-bid-approved'(data) {
      setActiveLotInfo('prices.currentPrice', data['bidPrice']);
    },
    finishedAt(data) {
      if (lotId === data.lotId) {
        set('finishedAt', data.finishedAt);
      }
      if (activeLotRef.current?.lotId === data.lotId) {
        setActiveLotInfo('finishedAt', data.finishedAt);
      }
    },
  };

  useEffect(() => {
    if (JSON.stringify(lot) !== '{}' && isAuthenticated && bidAllowed) {
      ws.connect();
      for (const event in WS_Events) {
        ws.on(event, WS_Events[event]);
      }
    }
  }, []);

  useEffect(() => {
    const cleanup = () => {
      ws.emit('leave', {rid: lotId, uid: userId});
      ws.disconnect();
      dispatch({type: CLEAR_LOT_DATA});
      dispatch({type: CLEAR_ACTIVE_LOT_DATA});
    };
    window.addEventListener('beforeunload', cleanup);

    return () => {
      cleanup();
      window.removeEventListener('beforeunload', cleanup);
    };
  }, []);

  const isShowBlocActiveLot =
    activeLot.lotId &&
    activeLot.lotId !== lot.lotId &&
    activeLot.auctionStatus === AUCTION_STATUS_LIVE &&
    lot.lotStatus !== LOT_STATUS_ACTIVE;

  if (!lot.prices) return <></>;
  return (
    <div className='AuctionBloc'>
      {isShowBlocActiveLot ? (
        <ShadowContainer className='ShadowContainerActiveLot'>
          <ActiveLot />
        </ShadowContainer>
      ) : null}
      <ShadowContainer className='mainContent'>
        <StartPrice />
        <ProgressBar lot={lot} />
        <CurrentBid lot={lot} />
        <MakeBit newBit={newBit} setNewBit={setNewBit} />
        <ConfirmBit
          socketRef={socketRef}
          newBit={newBit}
          setNewBit={setNewBit}
        />
        <FinalPrice />
        <ResultLot />
        <PurchaseProgress />
      </ShadowContainer>
    </div>
  );
}
export default AuctionBloc;
